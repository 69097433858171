<template>
  <div class="bg max-w1200 mrauto mt47 mb51 pd20">
    <div class="flex pd40 bg-ffffff bor-E6E6E6">
    <div>
      <div>
          <el-menu
            :default-active="navIndex"
            class="el-menu-vertical-demo"
            background-color="#F1F1F1"
            text-color="#222222"
            active-text-color="#CC0000"
            @select="select"
          >
            <el-menu-item index="1">
              <span slot="title">企业简介</span>
            </el-menu-item>
            <el-menu-item index="2">
              <span slot="title">合作推广</span>
            </el-menu-item>
            <el-menu-item index="3">
              <span slot="title">服务模式</span>
            </el-menu-item>
             <el-menu-item index="4">
              <span slot="title">公司资质</span>
            </el-menu-item>
             <el-menu-item index="5">
              <span slot="title">企业风采</span>
            </el-menu-item>
            <el-menu-item index="6">
              <span slot="title">公司历程</span>
            </el-menu-item>
            <el-menu-item index="7">
              <span slot="title">公司荣誉</span>
            </el-menu-item>
          </el-menu>
        </div>
    </div>
    <div class="plr30 per-w100">
      <div v-html="content"></div>
    </div>
    </div>
  </div>
</template>

<script>
import { mer_info } from "../../utils/api.js";
export default {
  data() {
    return {
      navIndex: "1",
      content: "",
    };
  },
  mounted() {
    this.navIndex = this.$route.query.type?this.$route.query.type:'1'
    this.getInfo();
  },
  methods: {
       select(e) {
      this.navIndex = e;
      this.getInfo();
    },
    getInfo() {
      let that = this;
      mer_info({
        type:this.navIndex
      })
        .then((res) => {
          console.log(res);
          that.content = res.data
            .replace(/<img/gi, '<img style="max-width:100%;height:auto;display:block" ')
            .replace(/<section/g, "<div")
            .replace(/\/section>/g, "div>");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
// .bg {
//   background-image: url(../../assets/img/bg.png);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
// }
.el-input.is-disabled .el-input__inner {
  background-color: #ffffff !important;
  border-color: #d2d2d2 !important;
  color: #222222 !important;
  font-size: 0.875rem !important;
  cursor: not-allowed;
}

</style>
